import { Link } from '@remix-run/react';

export default function Index() {
	return (
		<ul>
			<li>
				<Link to="/books">Books link</Link>
			</li>
			<li>
				<Link to="/scans/new">Scans</Link>
			</li>
		</ul>
	);
}
